import React, { ReactElement } from 'react'
import style from './style.less'
import DrivesOverview from '@yaak/components/src/DrivesOverview'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Version } from '@yaak/components/src/types'
import { getOffset } from '@yaak/nutron/src/utils/player'

interface ScenariosOverviewProps {
  token: string
}

const ScenariosOverview: React.FunctionComponent<ScenariosOverviewProps> = ({
  token,
}): ReactElement => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const context = searchParams.get('context')

  return (
    <div className={style.scenariosOverview}>
      <DrivesOverview
        scenarios={true}
        token={token}
        nutron={true}
        fixedColumns={1}
        version={Version.v2}
        onClick={(sId: string, begin?: number, end?: number) => {
          context &&
            begin !== undefined &&
            navigate(
              `/dataset/session-logs/${sId}?begin=${begin}&end=${end}&context=${parseInt(
                context
              )}&offset=${getOffset(begin, parseInt(context))}`
            )
        }}
        withHeader={false}
        searchQueryEnabled={true}
      />
    </div>
  )
}

export default ScenariosOverview
