import React from 'react'
import style from './style.less'
import CollectionCard from '@yaak/nutron/src/components/CollectionCard'
import datasetImg from '@yaak/components/assets/images/DatasetImg.png'
import { Dataset } from '@yaak/components/services/api/api'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import Button from '@yaak/components/src/Button'
import { Version } from '@yaak/components/src/types'
import Icon from '@yaak/components/src/Icon'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

// TODO: remove mock and image after API sends it
const mockDataset: Dataset = {
  name: 'CARLA',
  description: '',
  collectionCount: 2,
  totalDurationMs: 1152000,
  updatedAt: '2025-01-04T18:21:46.407274Z',
  id: '1',
  profile: 'Automotive',
  partnerId: '',
  infoUrl: '',
  imgUrl: datasetImg,
  modalities: ['Controls', 'Images'],
}

const CollectionsPage = () => {
  const { datasetId } = useParams()

  return (
    <div
      className={classNames(style.main, !datasetId ? style.page : undefined)}
    >
      {!datasetId && (
        <div className={style.header}>
          <Typography type={TypographyTypes.title} version={Version.v2}>
            Collections
          </Typography>
          <Button
            text="New collection"
            icon={<Icon name="Add" version={Version.v2} />}
            tertiary
            onClick={() => {}}
          />
        </div>
      )}
      <div className={style.container}>
        <CollectionCard dataset={mockDataset} showDatasetDetails={!datasetId} />
        <CollectionCard dataset={mockDataset} showDatasetDetails={!datasetId} />
        <CollectionCard dataset={mockDataset} showDatasetDetails={!datasetId} />
      </div>
    </div>
  )
}
export default CollectionsPage
