import React from 'react'
import style from './style.less'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import Icon from '@yaak/components/src/Icon'
import { Version } from '@yaak/components/src/types'
import Badge from '@yaak/components/src/Badge'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import Tooltip from '@yaak/components/src/Tooltip'
import { Dataset } from '@yaak/components/services/api/api'
import { format } from 'date-fns'
import { getTimezone } from '@yaak/nutron/src/utils/time'
import { toHours } from '@yaak/admin/src/helpers/time'
import Button from '@yaak/components/src/Button'

const profileType: Record<
  string,
  {
    icon: string
    iconType: string
  }
> = {
  Automotive: { icon: 'Car', iconType: BadgeType.yellow },
  Robotics: { icon: 'Robot', iconType: BadgeType.purple },
}

interface ICollectionCardProps {
  dataset: Dataset
  showDatasetDetails?: boolean
}

const CollectionCard = ({
  dataset,
  showDatasetDetails,
}: ICollectionCardProps) => {
  return (
    <div className={style.cardContainer}>
      <div className={style.content}>
        <div className={style.infoContainer}>
          {showDatasetDetails && (
            <Typography
              type={TypographyTypes.label}
              color="color-neutral-040"
              version={Version.v2}
            >
              {dataset.name}
            </Typography>
          )}
          <Typography type={TypographyTypes.title} version={Version.v2}>
            Collection title 1
          </Typography>
          <div className={style.metadataContainer}>
            {showDatasetDetails && (
              <>
                <div className={style.item}>
                  <Tooltip position="top" text="Visibility">
                    <Icon name="Visibility" version={Version.v2} />
                  </Tooltip>
                  <Typography type={TypographyTypes.body} version={Version.v2}>
                    Read-only
                  </Typography>
                </div>
                <span>.</span>
              </>
            )}
            <div className={style.item}>
              <Tooltip position="top" text="Last updated">
                <Icon name="Update" version={Version.v2} />
              </Tooltip>
              <Typography type={TypographyTypes.body} version={Version.v2}>
                {format(new Date(), 'dd MMM, HH:mm ')}
                {getTimezone(new Date())}
              </Typography>
            </div>
            <span>.</span>
            <div className={style.item}>
              <Tooltip position="top" text="Total hours">
                <Icon name="Clock" version={Version.v2} />
              </Tooltip>
              <Typography type={TypographyTypes.body} version={Version.v2}>
                {toHours(dataset.totalDurationMs)}
              </Typography>
            </div>
          </div>
        </div>
        <div className={style.footer}>
          <div className={style.badges}>
            {showDatasetDetails && dataset.profile && (
              <Badge
                type={profileType[dataset.profile].iconType}
                icon={
                  <Icon
                    name={profileType[dataset.profile].icon}
                    version={Version.v2}
                  />
                }
                label="Automotive"
              />
            )}
            <Button
              text="Config"
              icon={<Icon name="Code" version={Version.v2} />}
              onClick={() => {}}
              tertiary
            />
          </div>
          <Icon name="MoreHorizontal" />
        </div>
      </div>
    </div>
  )
}

export default CollectionCard
