import React, { useCallback, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@yaak/components/src/Button'
import yaakLogo from '@yaak/components/assets/images/yaakLogo2.0.svg'
import style from './style.less'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import Icon from '@yaak/components/src/Icon'
import Divider from '@yaak/components/src/Divider'

interface WelcomeDialogProps {
  header: string
}

const WelcomeDialog = ({ header }: WelcomeDialogProps) => {
  const welcomePopup = localStorage.getItem('welcomePopup')
  const [open, setOpen] = useState<boolean>(true)

  useEffect(() => {
    if (welcomePopup) {
      setOpen(!JSON.parse(welcomePopup))
    }
  }, [welcomePopup])

  const onClose = useCallback(() => {
    localStorage.setItem('welcomePopup', 'true')
    setOpen(false)
  }, [])

  // TODO: Use correct icon when available

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={open}>
      <div>
        <DialogTitle id="form-dialog-title">
          <div className={style.logoContainer}>
            <img src={yaakLogo} alt="Logo" className={style.logo} />
          </div>
          <Typography version={Version.v2} type={TypographyTypes.headline}>
            {header}
          </Typography>
          <Typography
            version={Version.v2}
            type={TypographyTypes.body}
            size={TypographySizes.large}
          >
            Register or log in to access all features.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={style.boxContainer}>
            <div className={style.box}>
              <Icon
                version={Version.v2}
                className={style.icon}
                name={'Collection'}
              />
              <Typography version={Version.v2} type={TypographyTypes.body}>
                Curate data into config collections
              </Typography>
            </div>
            <div className={style.box}>
              <Icon
                version={Version.v2}
                className={style.icon}
                name={'Collection'}
              />
              <Typography version={Version.v2} type={TypographyTypes.body}>
                Train with{' '}
                <a
                  className={style.link}
                  href={'https://github.com/yaak-ai/rbyte'}
                >
                  rbyte
                </a>{' '}
                &{' '}
                <a
                  className={style.link}
                  href={'https://huggingface.co/lerobot'}
                >
                  LeRobot
                </a>{' '}
                (coming soon)
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={style.left}>
          <Button
            secondary
            className={style.registerButton}
            onClick={() => {}}
            text={'Register now'}
          />
          <Button
            tertiary
            onClick={() => {
              onClose()
              window.location.href = 'https://nutron.yaak.ai/'
            }}
            text={'Log in'}
          />
        </DialogActions>
        <div className={style.footer}>
          <Divider />
          <div className={style.links}>
            <Typography
              version={Version.v2}
              type={TypographyTypes.body}
              size={TypographySizes.extraSmall}
            >
              Contact us
            </Typography>
            <Typography
              version={Version.v2}
              type={TypographyTypes.body}
              size={TypographySizes.extraSmall}
            >
              Privacy policy
            </Typography>
            <Typography
              version={Version.v2}
              type={TypographyTypes.body}
              size={TypographySizes.extraSmall}
            >
              Terms of Use
            </Typography>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default WelcomeDialog
