import React, { ReactElement, useContext, useState } from 'react'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { resetPassword } from '@yaak/components/services/api/api'
import { useAuth0 } from '@auth0/auth0-react'
import style from './style.less'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import TextField from '@mui/material/TextField'
import Button from '@yaak/components/src/Button'
import { Version } from '@yaak/components/src/types'
import Icon, { IconSizes } from '@yaak/components/src/Icon/Icon'
import { ICON_POSITIONS } from '@yaak/components/src/Button/Button'
import WarningDialog from '@yaak/components/src/WarningDialog'

interface SettingsPageProps {}

const SettingsPage: React.FunctionComponent<
  SettingsPageProps
> = (): ReactElement => {
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false)

  const { user: auth0User, logout } = useAuth0()

  const onSubmit = async () => {
    auth0User?.email &&
      (await resetPassword({
        email: auth0User.email,
        onAlert: setShowToast,
      }))
    setShowChangePasswordDialog(false)
  }

  return (
    <div className={style.settingsPage}>
      <div className={style.settings}>
        <Typography version={Version.v2} type={TypographyTypes.label}>
          ACCOUNT
        </Typography>
        <div className={style.settingsColumn}>
          <Typography
            version={Version.v2}
            type={TypographyTypes.headline}
            size={TypographySizes.small}
          >
            Profile
          </Typography>
          <Typography
            version={Version.v2}
            type={TypographyTypes.body}
            size={TypographySizes.large}
          >
            Manage your Nutron profile.
          </Typography>
        </div>
        <div className={style.settingsBox}>
          <Typography version={Version.v2} type={TypographyTypes.title}>
            Personal info
          </Typography>
          <TextField
            disabled
            defaultValue={`${auth0User?.given_name} ${auth0User?.family_name}`}
            InputProps={{ style: { borderRadius: '0.5rem' } }}
          />
          <TextField
            className={style.longField}
            disabled
            fullWidth
            placeholder={'Email'}
            defaultValue={auth0User?.email}
            InputProps={{ style: { borderRadius: '0.5rem' } }}
          />
        </div>
        <div className={style.settingsRow}>
          <Button
            iconPosition={ICON_POSITIONS.RIGHT}
            secondary
            text={'Log out'}
            onClick={() => {
              logout({ returnTo: window.location.origin })
            }}
            icon={<Icon name={'Logout'} />}
          />
          <Button
            tertiary
            text={'Reset password'}
            onClick={() => setShowChangePasswordDialog(true)}
          />
        </div>
      </div>
      <WarningDialog
        icon={<Icon name={'Email'} size={IconSizes.large} />}
        isOpen={showChangePasswordDialog}
        onSubmit={onSubmit}
        buttons={{
          submit: 'Ok',
        }}
        dialogTitle={'Reset your password'}
        dialogContentText={
          'Please check your email for the password reset link we sent you.'
        }
      />
    </div>
  )
}

export default SettingsPage
