import React from 'react'
import style from './style.less'
import yaakLogo from '@yaak/components/assets/images/yaakLogo2.0.svg'
import Icon, { IconTypes } from '@yaak/components/src/Icon'
import { Version } from '@yaak/components/src/types'
import classNames from 'classnames'
import Typography, {
  TypographySizes,
  TypographyStyles,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import Button from '@yaak/components/src/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { isSandboxUser } from '../../utils/sandbox'

interface ISidebarProps {
  bundledRelease: {
    commitHash: string
    releasedAt: string
  }
}

const Sidebar = ({ bundledRelease }: ISidebarProps) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isGuestUser = isSandboxUser()
  return (
    <div className={style.container}>
      <div className={style.sidebarContainer}>
        <div>
          <div className={style.logoContainer}>
            <img src={yaakLogo} alt="Logo" className={style.logo} />
            <Typography
              className={style.itemText}
              type={TypographyTypes.label}
              size={TypographySizes.large}
              version={Version.v2}
            >
              {isGuestUser ? 'Nutron Sandbox' : 'Nutron'}
            </Typography>
          </div>
          <div
            className={classNames(
              style.item,
              pathname.startsWith('/dataset') ? style.itemSelected : undefined
            )}
            onClick={() => navigate('/datasets')}
          >
            <Icon name="Dataset" version={Version.v2} />
            <Typography
              className={style.itemText}
              type={TypographyTypes.label}
              size={TypographySizes.large}
              version={Version.v2}
            >
              Datasets
            </Typography>
          </div>
          <div
            className={classNames(
              style.item,
              style.collections,
              pathname.startsWith('/collections')
                ? style.itemSelected
                : undefined
            )}
            onClick={() => navigate('/collections')}
          >
            <Icon name="Collection" version={Version.v2} />
            <Typography
              className={style.itemText}
              type={TypographyTypes.label}
              size={TypographySizes.large}
              version={Version.v2}
            >
              Collections
            </Typography>
          </div>
          <div
            className={classNames(
              style.item,
              style.settings,
              pathname.startsWith('/settings') ? style.itemSelected : undefined,
              isGuestUser ? style.itemDisabled : undefined
            )}
            onClick={() => !isGuestUser && navigate('/settings/account')}
          >
            <Icon name="Settings" version={Version.v2} />
            <Typography
              className={style.itemText}
              type={TypographyTypes.label}
              size={TypographySizes.large}
              version={Version.v2}
            >
              Settings
            </Typography>
          </div>
        </div>
        <div>
          <div className={classNames(style.item, style.helpIcon)}>
            <Icon name="Help" version={Version.v2} />
          </div>
          <div className={style.footerContainer}>
            <div className={style.linksContainer}>
              <div className={style.links}>
                <Typography
                  type={TypographyTypes.label}
                  size={TypographySizes.small}
                  version={Version.v2}
                  color="color-neutral-040"
                >
                  CONTACT
                </Typography>
                <a
                  href="https://www.yaak.ai/"
                  target="_blank"
                  rel="noreferrer"
                  className={style.link}
                >
                  <Typography
                    type={TypographyTypes.body}
                    size={TypographySizes.small}
                    version={Version.v2}
                  >
                    Request dataset
                  </Typography>
                  <Icon
                    name="ArrowOutward"
                    version={Version.v2}
                    type={IconTypes.regular}
                  />
                </a>
                <a
                  href="https://www.yaak.ai/"
                  target="_blank"
                  rel="noreferrer"
                  className={style.link}
                >
                  <Typography
                    type={TypographyTypes.body}
                    size={TypographySizes.small}
                    version={Version.v2}
                  >
                    Get in touch
                  </Typography>
                  <Icon name="ArrowOutward" version={Version.v2} />
                </a>
              </div>
              <div className={style.links}>
                <Typography
                  type={TypographyTypes.label}
                  size={TypographySizes.small}
                  version={Version.v2}
                  color="color-neutral-040"
                >
                  INFO
                </Typography>
                <a
                  href="https://www.yaak.ai/"
                  target="_blank"
                  rel="noreferrer"
                  className={style.link}
                >
                  <Typography
                    type={TypographyTypes.body}
                    size={TypographySizes.small}
                    version={Version.v2}
                  >
                    Privacy Policy
                  </Typography>
                  <Icon name="ArrowOutward" version={Version.v2} />
                </a>
                <a
                  href="https://www.yaak.ai/"
                  target="_blank"
                  rel="noreferrer"
                  className={style.link}
                >
                  <Typography
                    type={TypographyTypes.body}
                    size={TypographySizes.small}
                    version={Version.v2}
                  >
                    Terms of Use
                  </Typography>
                  <Icon name="ArrowOutward" version={Version.v2} />
                </a>
              </div>
            </div>
            <div className={style.versionContainer}>
              <Typography
                type={TypographyTypes.body}
                size={TypographySizes.small}
                color="color-neutral-040"
                version={Version.v2}
              >
                Version: {bundledRelease?.commitHash}
              </Typography>
              <Typography
                type={TypographyTypes.body}
                size={TypographySizes.small}
                color="color-neutral-040"
                version={Version.v2}
              >
                {new Date(bundledRelease.releasedAt).toLocaleString('en-GB')}
              </Typography>
            </div>
          </div>
          {isGuestUser && (
            <div className={style.authContainer}>
              <div className={style.textContainer}>
                <Typography
                  type={TypographyTypes.body}
                  size={TypographySizes.small}
                  styles={TypographyStyles.emphasis}
                  version={Version.v2}
                >
                  New to Nutron?
                </Typography>
                <Typography
                  type={TypographyTypes.body}
                  size={TypographySizes.small}
                  version={Version.v2}
                >
                  Register now to access all features.
                </Typography>
              </div>
              <div className={style.buttonContainer}>
                <Button text="Register" onClick={() => {}} />
                <Button text="Log in" tertiary onClick={() => {}} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Sidebar
