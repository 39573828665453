import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import config from '@yaak/components/services/api/config'
import App from './App'
import LightTheme from '@yaak/components/src/LightTheme'
import AccessDenied from '@yaak/components/src/AccessDenied'
import { createRoot } from 'react-dom/client'
import 'react-mosaic-component/react-mosaic-component.css'
import 'rsuite/dist/rsuite.min.css'
import * as Sentry from '@sentry/react'
import packageJson from '@yaak/nutron/package.json'

Sentry.init({
  dsn: config.sentry.dns,
  release: packageJson.version,
  environment: process.env.NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/embodied-staging.yaak\.ai/,
    /^https:\/\/nutron-staging.yaak\.ai/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

interface Auth0AppProps {
  sandbox: boolean
}

const Auth0App = ({ sandbox }: Auth0AppProps) => {
  const accessDeniedError = document.location.search.includes(
    'error=access_denied'
  )

  return sandbox ? (
    <LightTheme>
      <BrowserRouter>
        <App sandbox={sandbox} />
      </BrowserRouter>
    </LightTheme>
  ) : (
    <Auth0Provider
      audience={config.auth.audience}
      clientId={config.auth.clientId}
      domain={config.auth.domain}
      redirectUri={`${window.location.origin}?location=${window.location.pathname}`}
      scope={config.auth.scope}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <LightTheme>
        <BrowserRouter>
          {accessDeniedError ? <AccessDenied /> : <App sandbox={sandbox} />}
        </BrowserRouter>
      </LightTheme>
    </Auth0Provider>
  )
}

const rootElement = document.getElementById('root')
const root = rootElement && createRoot(rootElement)

export default Auth0App
root?.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <React.StrictMode>
      <Auth0App sandbox={JSON.parse(config.sandbox)} />
    </React.StrictMode>
  </Sentry.ErrorBoundary>
)
