import React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import DrivesPage from './pages/DrivesPage'
import ScenariosPage from './pages/ScenariosPage'
import MetricsPage from './pages/MetricsPage'
import DatasetsPage from './pages/DatasetsPage'
import SettingsPage from './pages/SettingsPage/SettingsPage'
import CollectionsPage from './pages/CollectionsPage'
import DatasetDetailsPage from './pages/DatasetDetailsPage'

interface RouterProps {
  token: string
  sandbox: boolean
}

function LegacyRedirectToDatasetDrives() {
  const { sessionId } = useParams()
  return <Navigate to={`/dataset/session-logs/${sessionId}`} />
}

const Router: React.FunctionComponent<RouterProps> = ({ token, sandbox }) => (
  <Routes>
    <Route path="/" element={<Navigate to="/datasets" />} />
    <Route path="/metrics/" element={<MetricsPage token={token} />} />
    {!sandbox && <Route path="/settings/account" element={<SettingsPage />} />}
    <Route
      path="/dataset/"
      element={<Navigate to="/dataset/session-logs/list" />}
    />
    <Route path="/datasets" element={<DatasetsPage token={token} />} />
    <Route
      path="/datasets/:datasetId/:tab"
      element={<DatasetDetailsPage token={token} />}
    />
    <Route path="/collections" element={<CollectionsPage />} />
    <Route
      path="/dataset/drives"
      element={<Navigate to="/dataset/session-logs/list" />}
    />
    <Route
      path="/dataset/session-logs/list"
      element={<DrivesPage token={token} />}
    />
    <Route
      path="/dataset/session-logs/grid"
      element={<DrivesPage token={token} />}
    />
    <Route
      path="/dataset/session-logs/:sessionId"
      element={<Dashboard token={token} />}
    />
    <Route
      path="/dataset/scenarios/list"
      element={<ScenariosPage token={token} />}
    />
    <Route
      path="/dataset/scenarios/grid"
      element={<ScenariosPage token={token} />}
    />
    {/* legacy support: */}
    <Route
      path="/dashboard/"
      element={<Navigate to="/dataset/session-logs/list" />}
    />
    {/* legacy support: */}
    <Route
      path="/dashboard/:sessionId"
      element={<LegacyRedirectToDatasetDrives />}
    />
  </Routes>
)

export default Router
