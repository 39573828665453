import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select'
import style from '../../pages/Settings/style.less'
import { findCameras, updateConfig } from '../../utils'
import Icon from '@yaak/components/src/Icon/Icon'
import MenuItem from '@mui/material/MenuItem'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import { useVideosStore } from '../../stores/VideosStore'
import { Camera } from '@yaak/components/services/api/api'
import { searchCamerasInLayout } from '../AddPanelSelect/utils'
import { useShallow } from 'zustand/react/shallow'
import {
  useMosaicStore,
  CAMERA_POSITIONS,
} from '@yaak/nutron/src/stores/MosaicStore'
import { MosaicParent } from 'react-mosaic-component'

interface TitleSelectProps {
  camera?: Camera
  pre: string
}

const TitleSelect = ({ camera, pre = '' }: TitleSelectProps) => {
  const [availableCameras, setAvailableCameras] = useState<string[]>()
  const {
    cameraPositions,
    selectedCameras,
    updateSelectedCameras,
    refresh,
    updateRefresh,
  } = useVideosStore(
    useShallow((state) => ({
      cameraPositions: state.cameraPositions,
      selectedCameras: state.selectedCameras,
      updateSelectedCameras: state.updateSelectedCameras,
      refresh: state.refresh,
      updateRefresh: state.updateRefresh,
    }))
  )
  const { update: updateVideoPlayerStore, config } = useMosaicStore()

  const [value, setValue] = useState(camera?.name || '')

  useEffect(() => {
    const cameras = findCameras(cameraPositions, selectedCameras)
    camera?.name && setAvailableCameras([camera?.name].concat(cameras))
  }, [camera, selectedCameras])

  useEffect(() => {
    if (refresh) {
      const cameras = findCameras(cameraPositions, selectedCameras)
      camera?.name && setAvailableCameras([camera?.name].concat(cameras))
      updateRefresh(false)
    }
  }, [refresh])

  return (
    <Select
      id={'titleSelect'}
      className={style.select}
      value={pre + value}
      variant={'outlined'}
      onChange={(event) => {
        const camera = event.target.value.replace('/topic/', '')
        setValue(camera)
        const index = selectedCameras.indexOf(value)
        const treeSize = searchCamerasInLayout(config.first).length
        config.first = updateConfig(
          config.first as MosaicParent<CAMERA_POSITIONS>,
          treeSize,
          camera,
          index
        )
        updateVideoPlayerStore(config)

        const updatedSelectedCameras = selectedCameras
        updatedSelectedCameras[index] = camera
        updateSelectedCameras(updatedSelectedCameras)
        updateRefresh(true)
      }}
      IconComponent={(_props) => {
        const opened = _props.className.toString().includes('iconOpen')
        return (
          <Icon
            className={style.iconSelect}
            name={opened ? 'ArrowDropUp' : 'ArrowDropDown'}
            version={Version.v2}
          />
        )
      }}
    >
      {availableCameras?.map((cameraPosition, index) => (
        <MenuItem key={index} value={pre + cameraPosition}>
          <Typography type={TypographyTypes.label} version={Version.v2}>
            {pre + cameraPosition}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  )
}

export default TitleSelect
