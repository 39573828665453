import style from './style.less'
import { SolveResultXY } from '@lightningchart/lcjs'

const colorsMap: Record<string, string> = {
  brake_pedal_normalized: LESS_COLORS['color-lime-036'],
  gas_pedal_normalized: LESS_COLORS['color-lime-062'],
  steering_angle_normalized: LESS_COLORS['new-color-purple-039'],
  speed: LESS_COLORS['new-color-yellow-059'],
  steering_angle: LESS_COLORS['new-color-purple-069'],
  acceleration: LESS_COLORS['color-pink-074'],
}

const unitMap: Record<string, string> = {
  steering_angle: ' (deg)',
  speed: ' (km/h)',
  acceleration: ' (m/s²)',
}

export const renderExternalTooltip = (hits: SolveResultXY[]) => {
  let innerHtml = '<table><thead>'
  innerHtml += '</thead><tbody>'

  hits.forEach((hit) => {
    const name = hit.series.getName()
    const color = colorsMap[name]
    const styles = `background:${color}; border:1px solid #FFF; width: 14px; height: 14px;`
    const box = `<div style="${styles}"></div>`
    innerHtml += `<tr><td class=${
      style.tooltipText
    }>${box}<div style="display:flex; justify-content:space-between; flex: 1; gap:24px; overflow: hidden;">
    <span>${name} ${unitMap[name] || ''}</span>
    <span>${hit.y.toFixed(3)}</span>
    </div></td></tr>`
  })
  innerHtml += '</tbody></table>'

  return innerHtml
}
