import React from 'react'
import style from './style.less'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import Icon from '@yaak/components/src/Icon'
import Tooltip from '@yaak/components/src/Tooltip'
import Badge from '@yaak/components/src/Badge'
import {
  modalitiesMap,
  profileType,
} from '@yaak/nutron/src/components/DatasetCard/DatasetCard'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import { toHours } from '@yaak/admin/src/helpers/time'
import { format } from 'date-fns'
import { getTimezone } from '@yaak/nutron/src/utils/time'
import classNames from 'classnames'
import { Dataset } from '@yaak/components/services/api/api'
import Breadcrumbs from '@yaak/components/src/Breadcrumbs'

interface IDatasetDetailsHeaderProps {
  dataset: Dataset
}

const DatasetDetailsHeader = ({ dataset }: IDatasetDetailsHeaderProps) => {
  return (
    <div className={style.header}>
      <div className={style.headerContainer}>
        <Breadcrumbs
          first={{
            text: 'Datasets',
            url: '/datasets',
          }}
          second={{
            text: 'Yaak Dataset',
          }}
        />

        <Tooltip position="right" text="Dataset info">
          <a
            href={dataset.infoUrl}
            className={style.infoLink}
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              name="Information"
              version={Version.v2}
              color="color-neutral-040"
            />
          </a>
        </Tooltip>
      </div>
      <div className={classNames(style.headerContainer, style.infoContainer)}>
        {dataset.profile && (
          <Badge
            type={profileType[dataset.profile].iconType}
            icon={
              <Icon
                name={profileType[dataset.profile].icon}
                version={Version.v2}
              />
            }
            label={dataset.profile}
          />
        )}
        {dataset.modalities?.map((modality) => (
          <Tooltip key={modality} position="top" text={modality}>
            <Badge
              type={BadgeType.grey}
              icon={
                <Icon name={modalitiesMap[modality]} version={Version.v2} />
              }
              label=""
            />
          </Tooltip>
        ))}
        <span className={style.verticalBar}></span>
        <div className={style.item}>
          <Tooltip position="top" text="Last updated">
            <Icon
              name="Update"
              version={Version.v2}
              color="color-neutral-040"
            />
          </Tooltip>
          <Typography type={TypographyTypes.body} version={Version.v2}>
            {format(new Date(dataset.updatedAt), 'dd MMM, HH:mm ')}
            {getTimezone(new Date(dataset.updatedAt))}
          </Typography>
        </div>
        <span>.</span>
        <div className={style.item}>
          <Tooltip position="top" text="Total hours">
            <Icon name="Clock" version={Version.v2} color="color-neutral-040" />
          </Tooltip>
          <Typography type={TypographyTypes.body} version={Version.v2}>
            {toHours(dataset.totalDurationMs)}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default DatasetDetailsHeader
