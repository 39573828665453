import React, { useMemo } from 'react'
import { useMetadataStore } from '../../stores/MetadataStore'
import styles from './style.less'
import {
  calculateAcceleration,
  Timestamp,
  TurnSignal,
  YAAK_SCHEMA_NAME_MAPPING,
} from '../../utils/protobufParse'
import { usePlayerStore } from '../../stores/PlayerStore'
import { useShallow } from 'zustand/react/shallow'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import MetadataRow from './MetadataRow'
import { useParams } from 'react-router-dom'
import { getOffset } from '@yaak/nutron/src/utils/player'

interface MetadataLogProps {}

const findData = (data: any[], timestamp: Timestamp) =>
  data?.filter((d) => d.time_stamp.seconds === timestamp.seconds)[0]

const convertPositionCovariance = (positionCovariance: number[]) =>
  positionCovariance.map((pc) => pc / 100)

const MetadataLog: React.FunctionComponent<MetadataLogProps> = () => {
  const { sessionId } = useParams()
  const { metadata, seconds, settings, loadingFinished } = useMetadataStore(
    useShallow((state) => ({
      metadata: state.metadata,
      seconds: state.seconds,
      updateMetadata: state.updateMetadata,
      updateSeconds: state.updateSeconds,
      settings: state.metadataSettings,
      loadingFinished: state.loadingFinished,
    }))
  )
  const { begin, offset, context } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      offset: state.offset,
      context: state.context,
    }))
  )

  const data = useMemo(() => {
    if (sessionId) {
      return metadata[sessionId]
    }
  }, [metadata, sessionId])

  const vehicleState = useMemo(() => {
    const vehicleStateData = data?.[YAAK_SCHEMA_NAME_MAPPING.vehicleState]
    if (vehicleStateData && seconds && seconds.length > 0 && loadingFinished) {
      const time =
        vehicleStateData[0]?.time_stamp.seconds +
        offset -
        getOffset(begin, context)
      return vehicleStateData.find((vS) => vS.time_stamp.seconds === (time | 0))
    }
  }, [offset, seconds, data, loadingFinished, begin, context])

  const timeStamp = useMemo(() => vehicleState?.time_stamp, [vehicleState])

  const vehicleMotion = useMemo(
    () =>
      timeStamp && data?.[YAAK_SCHEMA_NAME_MAPPING.vehicleMotion]
        ? findData(
            data[YAAK_SCHEMA_NAME_MAPPING.vehicleMotion] || [],
            timeStamp
          )
        : null,
    [data, timeStamp]
  )
  const gnss = useMemo(
    () =>
      timeStamp && data?.[YAAK_SCHEMA_NAME_MAPPING.gnss]
        ? findData(data[YAAK_SCHEMA_NAME_MAPPING.gnss] || [], timeStamp)
        : null,
    [data, timeStamp]
  )

  return (
    <div className={styles.metadataLog}>
      {data && (
        <table>
          <thead>
            <tr>
              <th className={styles.valCol}>
                <Typography
                  type={TypographyTypes.label}
                  color="color-neutral-040"
                  version={Version.v2}
                >
                  Property
                </Typography>
              </th>
              <th className={styles.valCol}>
                <Typography
                  type={TypographyTypes.label}
                  color="color-neutral-040"
                  version={Version.v2}
                >
                  Value
                </Typography>
              </th>
              <th className={styles.unitCol}>
                <Typography
                  type={TypographyTypes.label}
                  color="color-neutral-040"
                  version={Version.v2}
                >
                  Units
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {vehicleMotion && (
              <>
                {timeStamp && (
                  <MetadataRow
                    property="timestamp"
                    value={new Date(timeStamp.seconds).getTime()}
                  />
                )}
                {settings.acceleration.display && (
                  <MetadataRow
                    property="acceleration"
                    value={parseFloat(
                      calculateAcceleration(vehicleMotion).toFixed(3)
                    )}
                    unit="m/s²"
                  />
                )}
                {settings.brake_pedal_normalized.display && (
                  <MetadataRow
                    property="brake_pedal_normalized"
                    value={parseFloat(
                      (vehicleMotion.brake_pedal_normalized || 0).toFixed(3)
                    )}
                  />
                )}
                {settings.gear.display && (
                  <MetadataRow property="gear" value={vehicleMotion.gear} />
                )}
                {gnss && (
                  <>
                    {settings.heading.display && (
                      <MetadataRow
                        property="heading"
                        value={parseFloat((gnss.heading || 0).toFixed(6))}
                        unit="deg"
                      />
                    )}
                    {settings.heading_error.display && (
                      <MetadataRow
                        property="heading_error"
                        value={parseFloat((gnss.heading_error || 0).toFixed(6))}
                        unit="deg"
                      />
                    )}
                    {settings.hp_loc_altitude.display && (
                      <MetadataRow
                        property="hp_loc_altitude"
                        value={parseFloat(
                          (gnss.hp_loc_altitude || 0).toFixed(6)
                        )}
                      />
                    )}
                    {settings.hp_loc_latitude.display && (
                      <MetadataRow
                        property="hp_loc_latitude"
                        value={parseFloat(
                          (gnss.hp_loc_latitude || 0).toFixed(6)
                        )}
                      />
                    )}
                    {settings.hp_loc_longitude.display && (
                      <MetadataRow
                        property="hp_loc_longitude"
                        value={parseFloat(
                          (gnss.hp_loc_longitude || 0).toFixed(6)
                        )}
                      />
                    )}
                    {settings.position_covariance.display && (
                      <MetadataRow
                        property="position_covariance"
                        value={`[${convertPositionCovariance(
                          gnss.position_covariance
                        ).join(', ')}]`}
                        unit="cm²"
                      />
                    )}
                  </>
                )}
                {settings.speed.display && (
                  <MetadataRow
                    property="speed"
                    value={parseFloat((vehicleMotion.speed || 0).toFixed(3))}
                    unit="km/h"
                  />
                )}
                {settings.steering_angle.display && (
                  <MetadataRow
                    property="steering_angle"
                    value={parseFloat(
                      (vehicleMotion.steering_angle || 0).toFixed(3)
                    )}
                    unit="deg"
                  />
                )}
                {settings.steering_angle_normalized.display && (
                  <MetadataRow
                    property="steering_angle_normalized"
                    value={parseFloat(
                      (vehicleMotion.steering_angle_normalized || 0).toFixed(3)
                    )}
                  />
                )}
                {vehicleState && settings.turn_signal.display && (
                  <MetadataRow
                    property="turn_signal"
                    value={TurnSignal[vehicleState.turn_signal]}
                  />
                )}
              </>
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default MetadataLog
