import React, { useContext, useEffect, useState } from 'react'
import style from './style.less'
import DatasetDetailsHeader from './Header'
import { Dataset, getDataset } from '@yaak/components/services/api/api'
import Tabs from '@yaak/components/src/Tabs'
import { useNavigate, useParams } from 'react-router-dom'
import { useTabIndex } from '@yaak/components/customHooks/useTabIndex'
import CollectionsPage from '../CollectionsPage'
import DatasetListPage from '../DatasetListPage'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'

const tabsMapping: Record<number, string> = {
  0: 'list',
  1: 'collections',
}

interface DatasetDetailsPageProps {
  token: string
}

const DatasetDetailsPage = ({ token }: DatasetDetailsPageProps) => {
  const navigate = useNavigate()
  const { datasetId, tab } = useParams()
  const currentTab = useTabIndex(tabsMapping, tab)
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [dataset, setDataset] = useState<Dataset>()

  useEffect(() => {
    const getDatasetInfo = async (token?: string) => {
      if (datasetId) {
        const dataset = await getDataset({
          token,
          id: datasetId,
          onAlert: setShowToast,
        })
        setDataset(dataset)
      }
    }

    getDatasetInfo(token)
  }, [token, datasetId])

  return (
    <div className={style.main}>
      {dataset && <DatasetDetailsHeader dataset={dataset} />}
      <div className={style.tabs}>
        <Tabs
          initial
          currentTab={currentTab}
          setCurrentTab={(tab) => {
            if (tab !== currentTab) {
              navigate(`/datasets/${datasetId}/${tabsMapping[tab]}`)
            }
          }}
          tabs={[
            {
              index: 0,
              text: 'Search',
            },
            {
              index: 1,
              text: 'CollectionsPage',
            },
          ]}
        />
      </div>
      {currentTab === 0 && datasetId ? (
        <DatasetListPage token={token} datasetId={datasetId} />
      ) : (
        <CollectionsPage />
      )}
    </div>
  )
}
export default DatasetDetailsPage
