// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__scenariosOverview--9CIsC {\n  height: calc(100% - 3.25rem);\n  display: flex;\n  flex-direction: column;\n  border-radius: 1rem;\n  padding: 1.5rem 0;\n  background: #fff;\n  overflow: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ScenariosOverview/style.less"],"names":[],"mappings":"AAGA;EAEI,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAHJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .scenariosOverview {\n    height: calc(100% - 3.25rem);\n    display: flex;\n    flex-direction: column;\n    border-radius: @spacing-100;\n    padding: @spacing-150 0;\n    background: @color-white;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scenariosOverview": "style__scenariosOverview--9CIsC"
};
export default ___CSS_LOADER_EXPORT___;
