import React from 'react'
import { Version } from '@yaak/components/src/types'
import DrivesOverview from '@yaak/components/src/DrivesOverview'
import { useNavigate, useSearchParams } from 'react-router-dom'

interface DatasetListPageProps {
  token: string
  datasetId: string
}

const DatasetListPage = ({ token, datasetId }: DatasetListPageProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const context = searchParams.get('context')

  return (
    <div>
      <DrivesOverview
        datasetId={datasetId}
        token={token}
        nutron={true}
        fixedColumns={1}
        version={Version.v2}
        onClick={(sId: string) => {
          context &&
            navigate(
              `/dataset/session-logs/${sId}?&context=${parseInt(
                context
              )}&dataset=true`
            )
        }}
        withHeader={false}
        searchQueryEnabled={true}
      />
    </div>
  )
}
export default DatasetListPage
