import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import {
  DEFAULT_CONTROLS_WITH_CREATION,
  MosaicBranch,
  MosaicWindow,
} from 'react-mosaic-component'
import { useShallow } from 'zustand/react/shallow'
import Icon from '@yaak/components/src/Icon/Icon'
import ProgressBar from '@yaak/components/src/ProgressBar'
import style from './style.less'
import { useVideosStore } from '../../stores/VideosStore'
import { Camera } from '@yaak/components/services/api/api'
import { OnProgressProps } from 'react-player/base'
import { usePlayerStore } from '../../stores/PlayerStore'
import TitleSelect from '../TitleSelect'
import { useOnMountUnsafe } from '../../hooks/useOnMountUnsafe'
import Button from '@yaak/components/src/Button/Button'
import { useMetadataStore } from '../../stores/MetadataStore'
import {
  SafetyScore,
  YAAK_SCHEMA_NAME_MAPPING,
} from '../../utils/protobufParse'
import { ScalePosition, zoomComponent } from '../../utils/zoomComponent'
import { Version } from '@yaak/components/src/types'
import {
  LAYOUT_NODE,
  useMosaicStore,
  CAMERA_POSITIONS,
} from '@yaak/nutron/src/stores/MosaicStore'
import { MosaicParent } from 'react-mosaic-component/src/types'
import { useParams } from 'react-router-dom'
import { getOffset } from '@yaak/nutron/src/utils/player'

const MAX_BUFFER_LENGTH_SECONDS = 60
const MAX_BUFFER_SIZE_BYTES = 20 * 1024 * 1024
const NUMBER_OF_CAMERAS = 3

const findScore = (scores: SafetyScore[], offset: number) =>
  scores.filter((score) => score.clip.end_timestamp.seconds === offset)

interface VideoProps {
  id: string
  path: MosaicBranch[]
  token: string
}

const Video = ({ id, path, token }: VideoProps) => {
  const { sessionId } = useParams()
  const {
    initialCameras,
    cameras,
    selectedCameras,
    updateInitialCameras,
    updateSelectedCameras,
    updateSelectedCamera,
    session,
  } = useVideosStore(
    useShallow((state) => ({
      initialCameras: state.initialCameras,
      cameras: state.cameras,
      selectedCameras: state.selectedCameras,
      updateInitialCameras: state.updateInitialCameras,
      updateSelectedCameras: state.updateSelectedCameras,
      updateSelectedCamera: state.updateSelectedCamera,
      session: state.session,
    }))
  )
  const { config, update: updateMosiacStore } = useMosaicStore(
    useShallow((state) => ({
      config: state.config,
      update: state.update,
    }))
  )
  const { metadata } = useMetadataStore(
    useShallow((state) => ({
      metadata: state.metadata,
    }))
  )
  const [safetyScore, setSafetyScore] = useState<SafetyScore>()

  const data = useMemo(() => {
    if (sessionId) {
      return metadata[sessionId]
    }
  }, [metadata, sessionId])

  const {
    begin,
    offset,
    context,
    jump,
    buffering,
    playing,
    isEndReached,
    setCameraState,
    setOffset,
    update,
    removeCameraState,
    playbackRate,
  } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      offset: state.offset,
      jump: state.jump,
      context: state.context,
      buffering: state.buffering,
      update: state.update,
      playing: state.playing,
      isEndReached: state.isEndReached,
      setCameraState: state.setCameraState,
      setOffset: state.setOffset,
      removeCameraState: state.removeCameraState,
      playbackRate: state.playbackRate,
    }))
  )
  const offsetRef = useRef(getOffset(begin, context))
  const playStoreRef = useRef(usePlayerStore.getState().offset)

  const [video, setVideo] = useState<Camera>()
  const [isMain, setIsMain] = useState<boolean>(false)
  const player = useRef<ReactPlayer>(null)
  const playerWrapper = useRef<HTMLDivElement>(null)
  const [pos, setPos] = useState<ScalePosition>({
    scale: 1,
    x: 0,
    y: 0,
  })

  useEffect(() => {
    if (playerWrapper.current) {
      zoomComponent({
        container: playerWrapper.current,
        maxScale: 8,
        factor: 0.1,
        setPos,
      })
    }
  }, [playerWrapper.current])

  useOnMountUnsafe(() => {
    initialCameras.length < NUMBER_OF_CAMERAS &&
      updateInitialCameras([...initialCameras, id])
  })

  useEffect(() => {
    const safetyScoreData: SafetyScore[] =
      data?.[YAAK_SCHEMA_NAME_MAPPING.safetyScore] || []
    if (safetyScoreData) {
      const score = findScore(
        safetyScoreData,
        Math.floor(
          data?.[YAAK_SCHEMA_NAME_MAPPING.driveSessionInfo]?.[0]?.time_stamp
            .seconds + offset
        )
      )[0]
      setSafetyScore(score || null)
    }
  }, [offset, data])

  useEffect(() => {
    if (
      initialCameras.length === NUMBER_OF_CAMERAS &&
      selectedCameras.length === 0
    ) {
      updateSelectedCameras(initialCameras)
    }
  }, [initialCameras])

  useEffect(() => {
    setCameraState(id, { buffering: false })
    usePlayerStore.subscribe((state) => {
      playStoreRef.current = state.offset
    })
  }, [])

  useEffect(
    () => () => {
      removeCameraState(id)
    },
    []
  )

  useEffect(() => {
    setVideo(cameras.filter((v) => v.name === id)[0])
    setIsMain(initialCameras.findIndex((camera) => camera === id) !== -1)
  }, [cameras, id, initialCameras])

  useEffect(() => {
    if (player.current) {
      if (jump !== 0 && isFinite(jump)) {
        player.current.seekTo(jump, 'seconds')
      } else if (begin) {
        player.current.seekTo(begin, 'seconds')
      }
    }
  }, [jump, player.current, begin])

  useEffect(() => {
    if (session.offsetURLStartTimestamp && session.offsetURLEndTimestamp) {
      const playedSeconds =
        new Date(session.startTimestamp).getTime() + offset * 1000
      const startSeconds = new Date(session.offsetURLStartTimestamp).getTime()
      const endSeconds = new Date(session.offsetURLEndTimestamp).getTime()
      if (playedSeconds >= endSeconds || playedSeconds < startSeconds) {
        update({
          playing: false,
        })
        player.current?.seekTo(offsetRef.current, 'seconds')
      }
    }
  }, [session, offset, update])

  useEffect(() => {
    offsetRef.current = getOffset(begin, context)
  }, [begin, context])

  useEffect(() => {
    if (isEndReached) {
      player.current?.seekTo(offsetRef.current, 'seconds')
    }
  }, [isEndReached])

  const handleProgress = useCallback(
    (progress: OnProgressProps) => {
      isMain && playing && setOffset(progress.playedSeconds)
    },
    [playing, isMain]
  )

  const additionalControls = (id: string) => (
    <Icon
      name={'Settings'}
      version={Version.v2}
      key={id}
      className={style.settingsIcon}
      onClick={() => {
        const videoConfig = config.first as MosaicParent<LAYOUT_NODE>
        const updatedConfig =
          videoConfig.first === 'panel-video-settings'
            ? { ...(videoConfig.second as Object) }
            : {
                direction: 'row',
                first: 'panel-video-settings',
                second: videoConfig,
                splitPercentage: 33,
              }
        config.first = updatedConfig as MosaicParent<LAYOUT_NODE>
        updateMosiacStore(config)
        updateSelectedCamera(
          selectedCameras.filter((camera) => camera === id)[0]
        )
      }}
    />
  )

  return video?.url ? (
    <MosaicWindow<CAMERA_POSITIONS>
      toolbarControls={[
        additionalControls(id),
        DEFAULT_CONTROLS_WITH_CREATION[3],
      ]}
      path={path}
      title={(<TitleSelect camera={video} pre={'/topic/'} />) as any}
      key={id}
      className={style.mosaicWindow}
    >
      {buffering && (
        <div className={style.loadingContainer}>
          <ProgressBar />
        </div>
      )}
      <div className={style.videoWrapper} ref={playerWrapper}>
        <ReactPlayer
          ref={player}
          url={video.url}
          playbackRate={playbackRate}
          progressInterval={100}
          style={{
            transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`,
            transformOrigin: '0px 0px',
          }}
          config={{
            file: {
              forceHLS: true,
              hlsVersion: '1.5.7',
              hlsOptions: {
                maxBufferLength: MAX_BUFFER_LENGTH_SECONDS,
                maxBufferSize: MAX_BUFFER_SIZE_BYTES,
                startPosition: offset,
                maxFragLookUpTolerance: 0,
                xhrSetup: (xhr: XMLHttpRequest) => {
                  xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                },
              },
            },
          }}
          onEnded={() => {
            update({ playing: false, isEndReached: true })
          }}
          playing={playing && !buffering}
          onProgress={handleProgress}
          onBuffer={() => {
            setCameraState(id, { buffering: true })
          }}
          onSeek={() => {
            setCameraState(id, { buffering: false })
          }}
          onBufferEnd={() => {
            setCameraState(id, { buffering: false })
          }}
          onReady={() => {
            setCameraState(id, { buffering: false })
          }}
          onError={console.error}
        />
        {safetyScore?.score && (
          <div className={style.score}>
            <Button
              text={`${(safetyScore.score * 100).toFixed(0)}%`}
              onClick={() => {}}
            />
          </div>
        )}
        {pos.scale > 1 && (
          <div className={style.zoom}>
            <Button
              text={'Reset view'}
              onClick={() =>
                setPos({
                  x: 0,
                  y: 0,
                  scale: 1,
                })
              }
            />
          </div>
        )}
      </div>
    </MosaicWindow>
  ) : (
    <></>
  )
}

export default Video
