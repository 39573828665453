// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__settingsPage--jpZHn {\n  height: calc(100% - 3.25rem);\n  display: flex;\n  flex-direction: column;\n  border-radius: 1rem;\n  padding: 1.5rem 0;\n  background: #fff;\n  overflow: auto;\n}\n.style__settings--yHJoo {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin-left: 2rem;\n}\n.style__settingsColumn--kTkh_ {\n  display: flex;\n  flex-direction: column;\n}\n.style__settingsRow--QUHWO {\n  align-items: center;\n  display: flex;\n  gap: 1rem;\n}\n.style__settingsBox--izXw8 {\n  display: flex;\n  padding: 1rem;\n  flex-direction: column;\n  gap: 1.5rem;\n  border-radius: 4px;\n  border: 1px solid #e5e5e5;\n  width: 500px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SettingsPage/style.less"],"names":[],"mappings":"AAGA;EAEI,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAHJ;AALA;EAYI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;AAJJ;AAXA;EAmBI,aAAA;EACA,sBAAA;AALJ;AAfA;EAwBI,mBAAA;EACA,aAAA;EACA,SAAA;AANJ;AApBA;EA8BI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;AAPJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .settingsPage {\n    height: calc(100% - 3.25rem);\n    display: flex;\n    flex-direction: column;\n    border-radius: 1rem;\n    padding: 1.5rem 0;\n    background: @color-white;\n    overflow: auto;\n  }\n\n  .settings {\n    display: flex;\n    flex-direction: column;\n    gap: @spacing-100;\n    margin-left: @spacing-200;\n  }\n\n  .settingsColumn {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .settingsRow {\n    align-items: center;\n    display: flex;\n    gap: @spacing-100;\n  }\n\n  .settingsBox {\n    display: flex;\n    padding: @spacing-100;\n    flex-direction: column;\n    gap: @spacing-150;\n    border-radius: 4px;\n    border: 1px solid @color-neutral-090;\n    width: 500px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsPage": "style__settingsPage--jpZHn",
	"settings": "style__settings--yHJoo",
	"settingsColumn": "style__settingsColumn--kTkh_",
	"settingsRow": "style__settingsRow--QUHWO",
	"settingsBox": "style__settingsBox--izXw8"
};
export default ___CSS_LOADER_EXPORT___;
