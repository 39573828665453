import React, { useEffect, useState } from 'react'
import styles from './style.less'
import Typography, {
  TypographySizes,
  TypographyStyles,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import Icon from '@yaak/components/src/Icon'
import Tooltip from '@yaak/components/src/Tooltip'

const MetadataRow = ({
  property,
  value,
  unit,
}: {
  property: string
  value: string | number
  unit?: string
}) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    setCopied(false)
  }, [value])

  const onCopyIconClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation()
    event.preventDefault()
    navigator.clipboard.writeText(`${property}: ${value} ${unit || ''}`)
    setCopied(true)
  }
  return (
    <tr>
      <td className={styles.valCol}>
        <Typography
          type={TypographyTypes.body}
          size={TypographySizes.small}
          styles={TypographyStyles.emphasis}
          version={Version.v2}
        >
          {property}
        </Typography>
      </td>
      <td className={styles.valCol}>
        <div className={styles.copyIconContainer}>
          <Typography
            type={TypographyTypes.body}
            size={TypographySizes.small}
            styles={TypographyStyles.mono}
            version={Version.v2}
          >
            {value || '--'}
          </Typography>
          <Tooltip
            text={copied ? 'Copied' : 'Copy to clipboard'}
            position={property === 'timestamp' ? 'bottom' : 'top'}
          >
            <Icon
              className={styles.copyIcon}
              name="Information"
              version={Version.v2}
              onClick={onCopyIconClick}
              color="color-neutral-040"
            />
          </Tooltip>
        </div>
      </td>
      <td className={styles.unitCol}>
        <Typography
          type={TypographyTypes.body}
          size={TypographySizes.small}
          version={Version.v2}
        >
          {unit || '--'}
        </Typography>
      </td>
    </tr>
  )
}

export default MetadataRow
