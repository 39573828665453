import React, { useContext, useEffect, useState } from 'react'
import style from './style.less'
import DatasetCard from '@yaak/nutron/src/components/DatasetCard'
import { Dataset, getDatasets } from '@yaak/components/services/api/api'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import Button from '@yaak/components/src/Button'
import { Version } from '@yaak/components/src/types'
import Icon from '@yaak/components/src/Icon'
import { ICON_POSITIONS } from '@yaak/components/src/Button/Button'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'

const DatasetsPage = ({ token }: { token: string }) => {
  const [datasets, setDatasets] = useState<Dataset[]>()
  const { setShowToast } = useContext(ToastContext) as ToastContextType

  const getData = async (token?: string) => {
    const datasets = await getDatasets({ token, onAlert: setShowToast })
    setDatasets(datasets?.data)
  }

  useEffect(() => {
    getData(token)
  }, [token])

  return (
    <div className={style.main}>
      <div className={style.header}>
        <Typography type={TypographyTypes.title} version={Version.v2}>
          Datasets
        </Typography>
        <Button
          text="Request dataset"
          icon={<Icon name="ArrowForward" version={Version.v2} />}
          iconPosition={ICON_POSITIONS.RIGHT}
          tertiary
          onClick={() => {}}
        />
      </div>
      <div className={style.container}>
        {datasets?.map((dataset) => (
          <DatasetCard key={dataset.id} dataset={dataset} />
        ))}
      </div>
    </div>
  )
}
export default DatasetsPage
