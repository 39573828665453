import React from 'react'
import { Mosaic, MosaicParent, MosaicWindow } from 'react-mosaic-component'
import { ViewId } from '../Dashboard/Dashboard'
import controls from '../Dashboard/controls'
import classNames from 'classnames'
import VideoSettings from './VideoSettings'
import ChartSettings from './ChartSettings'
import {
  LAYOUT_NODE,
  PANEL_ID_CHART,
  PANEL_ID_MAP,
  PANEL_ID_METADATA,
  PANEL_ID_VIDEO,
  useMosaicStore,
} from '../../stores/MosaicStore'
import MetadataSettings from './MetadataSettings'
import MapSettings from './MapSettings'

interface SettingsProps {
  panelId?: string
}

const Settings = ({ panelId }: SettingsProps) => {
  const { update: updateMosaic, config: mosaicConfig } = useMosaicStore()
  return (
    <Mosaic<string>
      onChange={(newNode) => {
        if (!newNode) {
          const videoConfig = mosaicConfig.first as MosaicParent<LAYOUT_NODE>
          if (
            panelId === PANEL_ID_VIDEO &&
            videoConfig.first === 'panel-video-settings'
          ) {
            updateMosaic({
              ...mosaicConfig,
              first: videoConfig.second,
            })
          }
          if (
            (panelId === PANEL_ID_CHART ||
              panelId === PANEL_ID_METADATA ||
              panelId === PANEL_ID_MAP) &&
            mosaicConfig.first === 'panel-settings'
          ) {
            updateMosaic({
              ...(mosaicConfig.second as Object),
            })
          }
        }
      }}
      initialValue={{
        direction: 'column',
        first: 'settings',
        splitPercentage: 100,
        second: '',
      }}
      renderTile={(id, path) => {
        return id ? (
          panelId === PANEL_ID_CHART ? (
            <MosaicWindow<ViewId>
              path={path}
              title={'Plot panel'}
              key={id}
              toolbarControls={controls.settingsPanel()}
            >
              <ChartSettings />
            </MosaicWindow>
          ) : panelId === PANEL_ID_METADATA ? (
            <MosaicWindow<ViewId>
              path={path}
              title={'Metadata panel'}
              key={id}
              toolbarControls={controls.settingsPanel()}
            >
              <MetadataSettings />
            </MosaicWindow>
          ) : panelId === PANEL_ID_MAP ? (
            <MosaicWindow<ViewId>
              path={path}
              title={'Map panel'}
              key={id}
              toolbarControls={controls.settingsPanel()}
            >
              <MapSettings />
            </MosaicWindow>
          ) : (
            <MosaicWindow<ViewId>
              path={path}
              title={'Image panel'}
              key={id}
              toolbarControls={controls.settingsPanel()}
            >
              <VideoSettings />
            </MosaicWindow>
          )
        ) : (
          <></>
        )
      }}
      blueprintNamespace={'bp4'}
      className={classNames('mosaic-blueprint-theme')}
    />
  )
}

export default Settings
