import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  BarElement,
  Title,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import style from './style.less'
import {
  tooltipCallbacks,
  DEFAULT_COLOR,
  HORIZONTAL_BAR_CHART_COLORS,
} from './util'

ChartJS.register(BarElement, Title, Legend, LinearScale, Tooltip)

interface HorizontalBarChartProps {
  title: string
  labels: string[]
  legend?: string[]
  data: number[]
  footer?: string
  stacked?: boolean
  formatValue?: (value: number | undefined) => string | undefined
}

const HorizontalBarChart = ({
  title,
  labels,
  legend,
  data,
  footer,
  stacked,
  formatValue,
}: HorizontalBarChartProps) => {
  const chartRef = useRef<any>(null)
  const [max, setMax] = useState<number>(0)

  let datasets = [
    {
      data,
      backgroundColor: labels?.map(
        (i) => HORIZONTAL_BAR_CHART_COLORS[i] || DEFAULT_COLOR
      ),
      borderColor: labels?.map(
        (i) => HORIZONTAL_BAR_CHART_COLORS[i] || DEFAULT_COLOR
      ),
      borderWidth: 1,
    },
  ]

  if (stacked) {
    datasets = []
    data.forEach((d, i) => {
      datasets.push({
        data: [d],
        backgroundColor: [
          HORIZONTAL_BAR_CHART_COLORS[legend?.[i] || ''] || DEFAULT_COLOR,
        ],
        borderColor: [
          HORIZONTAL_BAR_CHART_COLORS[legend?.[i] || ''] || DEFAULT_COLOR,
        ],
        borderWidth: 1,
        label: legend?.[i] || i + 1,
      } as any)
    })
  }

  useEffect(() => {
    let max = 0
    data.forEach((d) => {
      max += d
    })
    setMax(max)
  }, [data])

  const chartData = {
    labels,
    datasets,
  }

  const updateMax = {
    id: 'updateMax',
    afterDatasetUpdate(chart: any) {
      let max = 0
      const visibleDataset = chart.data.datasets.filter(
        (ds: any, i: number) => {
          return chart.isDatasetVisible(i) ? ds : undefined
        }
      )
      visibleDataset.forEach((d: any) => {
        max += d
      })
      setMax(max)
    },
  }

  const options = useMemo(() => {
    return {
      animation: false,
      barThickness: stacked ? 25 : 5,
      data: chartData,
      indexAxis: 'y',
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: !!stacked,
          position: 'bottom',
          labels: {
            boxHeight: 10,
            boxWidth: 10,
            font: {
              size: 10,
            },
          },
        },
        title: {
          align: 'start',
          display: true,
          text: title,
        },
        tooltip: {
          callbacks: tooltipCallbacks({ formatValue }),
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          border: {
            display: !stacked,
          },
          grid: {
            display: !stacked,
          },
          ticks: {
            display: !stacked,
          },
          title: {
            display: !!footer,
            text: footer,
          },
          max,
          min: 0,
        },
        y: {
          ticks: {
            display: false,
          },
          border: {
            display: !stacked,
          },
          grid: {
            display: !stacked,
          },
          stacked: true,
          max,
          min: 0,
        },
      },
      type: 'bar',
    }
  }, [max])

  return (
    <div className={style.horizontalBar}>
      <Bar
        ref={chartRef}
        options={options as any}
        data={chartData}
        plugins={[updateMax]}
      />
    </div>
  )
}

export default HorizontalBarChart
