// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__main--hjJtp {\n  height: 100%;\n  background-color: #ffffff;\n}\n.style__page--u9kq6 {\n  padding: 1rem 1.5rem;\n}\n.style__container--xeIum {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));\n  gap: 1rem;\n  overflow-x: hidden;\n}\n.style__header--Rd2Fn {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  border-bottom: 1px solid #e5e5e5;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/CollectionsPage/style.less"],"names":[],"mappings":"AAIA;EAEI,YAAA;EACA,yBAAA;AAJJ;AACA;EAOI,oBAAA;AALJ;AAFA;EAWI,aAAA;EACA,4DAAA;EACA,SAAA;EACA,kBAAA;AANJ;AARA;EAkBI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gCAAA;AAPJ","sourcesContent":["@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/colors';\n@import '@yaak/components/src/shadows';\n\n:local {\n  .main {\n    height: 100%;\n    background-color: @color-neutral-100;\n  }\n\n  .page {\n    padding: @spacing-100 @spacing-150;\n  }\n\n  .container {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));\n    gap: 1rem;\n    overflow-x: hidden;\n  }\n\n  .header {\n    display: flex;\n    align-items: center;\n    gap: @spacing-100;\n    border-bottom: 1px solid @color-neutral-090;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "style__main--hjJtp",
	"page": "style__page--u9kq6",
	"container": "style__container--xeIum",
	"header": "style__header--Rd2Fn"
};
export default ___CSS_LOADER_EXPORT___;
