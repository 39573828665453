import React from 'react'
import style from './style.less'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import Icon from '@yaak/components/src/Icon'
import { Version } from '@yaak/components/src/types'
import Badge from '@yaak/components/src/Badge'
import { BadgeType } from '@yaak/components/src/Badge/Badge'
import Tooltip from '@yaak/components/src/Tooltip'
import { Dataset } from '@yaak/components/services/api/api'
import { format } from 'date-fns'
import { getTimezone } from '@yaak/nutron/src/utils/time'
import { toHours } from '@yaak/admin/src/helpers/time'
import { useNavigate } from 'react-router-dom'

export const profileType: Record<
  string,
  {
    icon: string
    iconType: string
  }
> = {
  Automotive: { icon: 'Car', iconType: BadgeType.yellow },
  Robotics: { icon: 'Robot', iconType: BadgeType.purple },
}

export const modalitiesMap: Record<string, string> = {
  Controls: 'Tune',
  Images: 'Image',
  Videos: 'Video',
  Geospatial: 'Location',
}

interface IDatasetCardProps {
  dataset: Dataset
}

const DatasetCard = ({ dataset }: IDatasetCardProps) => {
  const navigate = useNavigate()
  return (
    <div
      className={style.cardContainer}
      onClick={() => navigate(`/datasets/${dataset.id}/list`)}
    >
      {dataset.imgUrl ? (
        <img src={dataset.imgUrl} alt="preview img" />
      ) : (
        <div className={style.previewUnavailable}>
          <Typography
            type={TypographyTypes.body}
            size={TypographySizes.small}
            version={Version.v2}
          >
            Preview unavailable.
          </Typography>
        </div>
      )}
      <div className={style.content}>
        <div className={style.infoContainer}>
          <Typography type={TypographyTypes.title} version={Version.v2}>
            {dataset.name}
          </Typography>
          <div className={style.metadataContainer}>
            <div className={style.item}>
              <Tooltip position="top" text="Collections">
                <Icon name="Collection" version={Version.v2} />
              </Tooltip>
              <Typography type={TypographyTypes.body} version={Version.v2}>
                {dataset.collectionCount}
              </Typography>
            </div>
            <span>.</span>
            <div className={style.item}>
              <Tooltip position="top" text="Last updated">
                <Icon name="Update" version={Version.v2} />
              </Tooltip>
              <Typography type={TypographyTypes.body} version={Version.v2}>
                {format(new Date(dataset.updatedAt), 'dd MMM, HH:mm ')}
                {getTimezone(new Date(dataset.updatedAt))}
              </Typography>
            </div>
            <span>.</span>
            <div className={style.item}>
              <Tooltip position="top" text="Total hours">
                <Icon name="Clock" version={Version.v2} />
              </Tooltip>
              <Typography type={TypographyTypes.body} version={Version.v2}>
                {toHours(dataset.totalDurationMs)}
              </Typography>
            </div>
          </div>
        </div>
        <div className={style.modalitiesContainer}>
          <div className={style.badges}>
            {dataset.profile && (
              <Badge
                type={profileType[dataset.profile].iconType}
                icon={
                  <Icon
                    name={profileType[dataset.profile].icon}
                    version={Version.v2}
                  />
                }
                label={dataset.profile}
              />
            )}
            <div className={style.modalities}>
              {dataset.modalities?.map((modality) => (
                <Tooltip key={modality} position="top" text={modality}>
                  <Badge
                    type={BadgeType.grey}
                    icon={
                      <Icon
                        name={modalitiesMap[modality]}
                        version={Version.v2}
                      />
                    }
                    label=""
                  />
                </Tooltip>
              ))}
            </div>
          </div>
          <Tooltip position="top" text="Dataset info">
            <a
              href={dataset.infoUrl}
              className={style.infoLink}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="Information"
                version={Version.v2}
                color="color-neutral-040"
              />
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default DatasetCard
